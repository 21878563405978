<template>
  <v-app-bar app height="100" color="#E8C9B1">
    <div id="logoContainer">
      <router-link to="/admin">
        <v-img
            :src="getAssetImg('logo/Logo_Regular.png')"
            height="100px"
            width="180px"
            contain
            max-width="180px"
            class="mr-4"
        ></v-img>
      </router-link>
    </div>

    <v-tabs align-with-title>
      <v-spacer></v-spacer>
      <v-tab :to="{ name: 'retailerOrdersList' }" class="font-weight-bold">Orders</v-tab>
      <v-tab :to="{ name: 'retailerUserView' }" class="font-weight-bold">User</v-tab>
      <v-tab @click="logout" class="font-weight-bold">Logout</v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
export default {
  components: {},
  name: "RetailerNavBarFull",
  methods: {
    logout() {
      this.$cookies.remove("sesh");
      this.$router.push({name: "home"});
    },
  },
};
</script>

<style scoped>
#logoContainer {
  width: 100px;
}

.headline {
  cursor: pointer;
}
</style>
