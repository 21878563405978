<template>
  <!-- <nav-bar-collapsed v-if="this.$vuetify.breakpoint.smAndDown"></nav-bar-collapsed> -->
  <retailer-nav-bar-full></retailer-nav-bar-full>
</template>

<script>
import RetailerNavBarFull from './RetailerNavBarFull.vue';

export default {
  components: {RetailerNavBarFull},
  name: "RetailerNavBar",
};
</script>

<style scoped>

</style>
